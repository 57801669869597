<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <h2 class="text-center mb-3">What would you like us to do?</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(item, id) in options"
        :key="id"
        cols="12"
        sm="6"
        md="3"
      >
        <v-btn
          class="btn-options pa-5"
          block
          :to="item.url"
          :disabled="item.disabled"
          @click.native="scrollToTop()"
        >
          <v-icon color="primary">{{ item.icon }}</v-icon>
          <br>
          {{ item.name }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { briefingData } from '@/data/briefingData'

export default ({
  data() {
    return {
      briefing: briefingData,
      options: [
        {
          name: 'Content Based Websites',
          value: 'content',
          icon: 'mdi-desktop-mac',
          url: 'content',
          disabled: false
        },
        {
          name: 'Promotional Microsites',
          value: 'promotional',
          icon: 'mdi-web',
          url: 'promotional',
          disabled: false
        },
        {
          name: 'Ecommerce',
          value: 'ecommerce',
          icon: 'mdi-cart',
          url: 'ecommerce',
          disabled: false
        },
        {
          name: 'MYY App Campaign or Partnership',
          value: 'myy_app',
          icon: 'mdi-cellphone',
          url: 'myyapp',
          disabled: true
        }
      ]
    }
  }
})
</script>

<style lang="scss">
.v-btn.btn-options {
  white-space: normal;
  display: block;
  height: 100% !important;
  text-align: center;
  .v-btn__content {
    display: inline-block;
    flex: auto;
    .v-icon {
      margin-bottom: 1.25rem;
      font-size: 50px;
    }
  }
}
</style>
